















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'

@Component({
  components: {
    TablePagination
  }
})
export default class MedicalPatientsIndex extends Vue {
  search = ''
  done = true
  patients = [] as any
  deleteModalIsOpened = false

  filter = {
    caseFilter: [],
    left: undefined,
    right: undefined,
    sex: undefined,
    ageRange: undefined
  }

  @Watch('$route.query.case')
  @Watch('$route.query.left')
  @Watch('$route.query.right')
  @Watch('$route.query.sex')
  @Watch('$route.query.ageRange')
  onRouteChange () {
    this.fetch()
  }

  created () {
    this.fetch()
  }

  fetch (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    const { caseFilter, left, right, sex, ageRange } = this.filter

    const filterSnake = snakeCaseKeys({
      case: caseFilter.length ? caseFilter.join(',') : undefined,
      left: left || right ? left : undefined,
      right: left || right ? right : undefined,
      sex,
      ageRange
    })

    if (this.$router.resolve({ query: filterSnake }).href !== this.$route.fullPath) {
      this.$router.push({
        query: filterSnake
      })
    }

    axios.get('patient', {
      params: {
        'page[number]': page,
        ...perPageSnake,
        ...filterSnake
      }
    })
      .then(response => {
        this.patients = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }
}
